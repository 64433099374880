
export default class Model {
    constructor() {
        this.error = [];

        if (new.target === Model) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }

        this.initialValues = {

            "folha_pgto_item_id": {
                onCreate: "0",
                type: "int"
            },
            "folha_pgto_id": {
                onCreate: "1",
                type: "mediumint"
            },
            "valor": {
                onCreate: "1",
                formatter: ["formatterReal"],
                mask: "BRL2",
                type: "decimal"
            },
            "colaborador_id": {
                onCreate: "1",
                type: "bigint"
            },
            "folha_pgto_item_status_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_created_at": {
                onCreate: "0",
                onPatch: "0",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "datetime"
            },
            "_created_user_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_updated_user_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_updated_at": {
                onCreate: "0",
                mask: "dmY",
                type: "datetime"
            }
        };
    }
    validate() {
        throw new Error('Método "validate" deve ser implementado pelas subclasses.');
    }
    create() {
        throw new Error('Método "create" deve ser implementado pelas subclasses.');
    }
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }
}
