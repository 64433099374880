import { useState } from 'react';

const usePermission = (str) => {
    //console.clear();
    //console.log("usePermission");
    
    // Estados para armazenar valores
    const [actionsOfCS, setActionsOfCS] = useState([]);
    const [permission, setPermission] = useState([]);
    const [permissionOfCS, setPermissionOfCS] = useState([]);
    const [thisAction, setThisAction] = useState('');
    const [thisScreenId, setThisScreenId] = useState('');
    const [thisCurrentClientId, setThisCurrentClientId] = useState('');

    const verbs = {
        show: "A00",
        post: "A01",
        create: "A01",
        list: "A02",
        patch: "A03",
        delete: "A04",
        search: "A05",
        view: "A06"
    };

    //console.log(`----------------- CARREGOU!!  -------------------------`);

    // Funções para definir e obter os valores
    const getAction = () => thisAction;

    const getScreenId = () => thisScreenId;

    const getCurrentClientId = () => thisCurrentClientId;

    const getPermission = () => permission;

    // Lógica para verificar a permissão
    const flag = (flagNeed, args = undefined) => {
        const getScreenIdLocal = args ?? getScreenId();

        // Verifica se é uma permissão genérica
        if (flagNeed in verbs) flagNeed = verbs[flagNeed];

        const index = str.find(
            (el) => el.screen_id === getScreenIdLocal && el.client_id_array.split(',').includes(getCurrentClientId())
        );

        if (flagNeed) {
            if (index) {
                const actions = index.permission.match(/A\d{2}/g);
                setActionsOfCS(actions);
                
                let permissionArray = index.permission.split(',');
                permissionArray = actions.concat(permissionArray);
                setPermissionOfCS(permissionArray);

                return permissionArray.includes(flagNeed);
            } else {
                return false;
            }
        } else {
            return index;
        }
    };

    // Outra função para verificar flag
    const hasStatus = (statusClass, screenId = undefined) => {
        const getScreenIdLocal = screenId ?? getScreenId();
        

        const statt=str.find((el) => el.screen_id === getScreenIdLocal);
        return statt.permission_status[statusClass]

    }

    const hasFlag = (flagNeed, screenId = undefined) => {
        //console.clear();
        const getScreenIdLocal = screenId ?? getScreenId();

        if (flagNeed in verbs) flagNeed = verbs[flagNeed];
        const index = str.find((el) => el.screen_id === getScreenIdLocal);

        if (flagNeed && index) {            
            const actions = index.permission.match(/A\d{2}/g);
            let permissionArray = index.permission.split(',');
            permissionArray = actions.concat(permissionArray);            
            return permissionArray.includes(flagNeed);            
        } else {
            return false;
        }
    };

    // Retorna as funções e os estados que podem ser usados no componente
    return {
        setAction: setThisAction,
        getAction,
        setPermission,
        getPermission,
        setScreenId: setThisScreenId,
        getScreenId,
        setCurrentClientId: setThisCurrentClientId,
        getCurrentClientId,
        flag,
        hasStatus,
        hasFlag
    };
};

export default usePermission;
