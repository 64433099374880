import { UseGeneratePdf } from "../../utils/generatePdf";

const UseCaseGeneratePdf = {

    GeneratePdf: async (args) => {
        console.clear();
        console.log("GeneratePdf::args", args);
        args.workSpace.folha_pgto.emmiter_user_name=args.session.USER_SHORT_NAME
        let content = {
            "header": args.workSpace.folha_pgto,
            "body_header": args.workSpace.folha_pgto,
            "body_content": args.data,
            "body_footer": {
                "total": args.valor_total
            },
            "footer": ""
        }

        let configs = {
            "outputFilename": args.workSpace.folha_pgto.folha_pgto_name.toLowerCase()
        }

        UseGeneratePdf({ content: content, configs: configs })
    }
}

export default UseCaseGeneratePdf;

