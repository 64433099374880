
         
import React, { useEffect, useState, useRef, useContext } from 'react'
/* 
import FragmentForm from './fragmentForm';
import FragmentSearch from './fragmentSearch'; */
import FragmentList from './fragmentList';
import { Aside, Header, Footer } from "../../fragments/index";
import Breadcrumb from './breadcrumb.jsx'

//import { AuthContext } from '../../Contexts/Auth/AuthContext'
import ContentProvider from '../../Contexts/Contents/Provider.js';

function Class() {
    //const { setAuth, auth, provider_session, provider_current_client } = useContext(AuthContext)
    //const SCREEN_ID = '526';
    useEffect(() => {
        console.log('%c useEffect - [ ]: Seta as permissões', 'color: #000; background-color: green;');        
    }, [])

    return (
        <ContentProvider>
            <div id="wrapper">
                <Aside />
                <div id="page-wrapper" class="gray-bg">
                    <Header />
                    <Breadcrumb page="comissao" />
                    {/* <FragmentForm /> */}
                    <FragmentList />
                    <Footer />
                </div>                
            </div>
        </ContentProvider>
    );
}

export default Class;

    