import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_CLIENT,
  headers: {
    "Is-Api": true,
    "Entity-Public-Token": "host.com.br",
  }
});

// Adiciona um interceptor para modificar os headers antes de cada requisição
instance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('authToken');  // Obtém o token de onde ele está armazenado
  if (authToken) {
    config.headers['Token-Authorization'] = authToken;  // Define o token no header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default instance;