import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../../src/domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SelectSingleDbColaborador = ({ field, formik,loading, options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref }) => {      
                const [listcolaborador, isLoadingcolaborador, isErrorcolaborador] = useRead({
                    table: "tb_colaborador",
                    src: 'colaborador',
                    where: [["colaborador_id", "!=", "0"]],
                    orderBy: "colaborador_name asc",
                    limit: "150"
                },ReadSearch);
            return (
                <CustomDropdown
                    field={field}
                    label={label??"Colaborador"}
                    options={[listcolaborador, 'tb_colaborador', 'colaborador_name', 'colaborador_id']} 
                    labelField="name" 
                    loading={isLoadingcolaborador} 
                    //listener={onListener}
                    optionSelected='colaborador_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Colaborador"
                    formik={formik}
                />

            );
        }

        export default SelectSingleDbColaborador; 