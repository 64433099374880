export default class Model {
    constructor() {
        this.error = [];

        if (new.target === Model) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }

        this.initialValues = {

            "colaborador_id": {
                onCreate: "0",
                type: "bigint"
            },
            "colaborador_name": {
                onCreate: "1",
                formatter: ["formatterTrim"],
                type: "varchar"
            },
            "cpf": {
                onCreate: "1",
                formatter: ["formatterInteger"],
                mask: "cpf",
                type: "bigint"
            },
            "ctps": {
                formatter: ["formatterNumber"],
                type: "varchar"
            },
            "dt_nascimento": {
                onCreate: "1",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "date"
            },
            "colaborador_status_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_created_user_id": {
                onCreate: "0",
                formatter: ["formatterInteger"],
                type: "mediumint"
            },
            "_created_at": {
                onCreate: "0",
                formatter: ["formatterDate"],
                mask: "dmYHis",
                type: "datetime"
            },
            "_updated_user_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_updated_at": {
                onCreate: "0",
                mask: "dmY",
                type: "datetime"
            },
            "filiacao_01_name": {
                type: "varchar"
            },
            "filiacao_02_name": {
                type: "varchar"
            },
            "sexo": {
                onCreate: "1",
                type: "char"
            },
            "naturalidade": {
                onCreate: "1",
                type: "varchar"
            },
            "uf": {
                onCreate: "1",
                formatter: ["formatterTrim"],
                type: "varchar"
            },
            "estado_civil_id": {
                onCreate: "1",
                type: "tinyint"
            },
            "instrucao": {
                type: "varchar"
            },
            "doc_rg": {
                type: "varchar"
            },
            "doc_rg_emissao": {
                type: "date"
            },
            "doc_rg_orgao_emissor": {
                type: "varchar"
            },
            "doc_cnh": {
                type: "varchar"
            },
            "dt_admissao": {
                onCreate: "1",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "date"
            },
            "forma_salario": {
                type: "varchar"
            }
        };
    }
    validate() {
        throw new Error('Método "validate" deve ser implementado pelas subclasses.');
    }
    create() {
        throw new Error('Método "create" deve ser implementado pelas subclasses.');
    }
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }
}