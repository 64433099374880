   
            import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
//import { AbstractSearch } from './abstractSearch';
import { FormatterValidator } from '../../infra/adapters/formatterValidator'
import Model from './model';
/* import { loadItemFromLocalStorage,saveToLocalStorage } from "../../utils/localStorageUser"; */

export default class useServiceSearch extends Model {
    constructor() {
        super();
    }


    async execute(args, token) {        
        instance.defaults.headers["Token-Authorization"] = token;
        console.log("useCaseSearch::execute: ", args);
        const response = await instance({
            url: `/comissao_item${ArrayToQParams(args)}&method=search&search=true`,
            method: "GET"
        }).catch(r => {
            console.log("useCaseSearch::CATCH: ", r.response);
            if (r.response.status == 403)
                throw ({
                    status: 403,
                    result: "",
                    resume: "Erro personalizado"
                });
            else
                throw ({
                    result: "",
                    status: r.response.status,
                    resume: "deu erro no else"
                });

        })

        console.log("~ RESPOSTA API ~", response);
        return response;
    }
    formatting(args) {
        let res = FormatterValidator.formatting(args, this.initialValues)
        this.payload = res;
        return res
    }

    async searching() {
        
        let where = [];

        const d = this.payload[0];
        
                if (d.comissao_item_id)
                    where.push(["A.comissao_item_id", "IN", d.comissao_item_id.map(item => item.code)]);
                 
                if (d.colaborador_id)
                    where.push(["A.colaborador_id", "IN", d.colaborador_id.map(item => item.code)]);
                 
                if (d.lancamento_id)
                    where.push(["A.lancamento_id", "IN", d.lancamento_id.map(item => item.code)]);
                 
                if (d.folha_pgto_id)
                    where.push(["A.folha_pgto_id", "IN", d.folha_pgto_id.map(item => item.code)]);
                 
                if (d.comissao_item_status_id)
                    where.push(["A.comissao_item_status_id", "IN", d.comissao_item_status_id.map(item => item.code)]);
                 
                if (d.comissao_id)
                    where.push(["A.comissao_id", "IN", d.comissao_id.map(item => item.code)]);
                
        return {
            leftJoin: true,
            where: where,
            orderBy: "A.comissao_item_id DESC",
            rowsCountTotal: true,
            page: 1,
            limit: 10
        }
    }

}

           
        