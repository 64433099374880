import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
//import { AbstractSearch } from './abstractSearch';
import { FormatterValidator } from '../../infra/adapters/formatterValidator'
import ClassModel from './model';
/* import { loadItemFromLocalStorage,saveToLocalStorage } from "../../utils/localStorageUser"; */

export default class useServiceSearch extends ClassModel {
    constructor() {
        super();
    }


    async execute(args, token) {        
        instance.defaults.headers["Token-Authorization"] = token;
        console.log("useCaseSearch::execute: ", args);
        const response = await instance({
            url: `/folha_pgto${ArrayToQParams(args)}&method=search&search=true`,
            method: "GET"
        }).catch(r => {
            console.log("useCaseSearch::CATCH: ", r.response);
            if (r.response.status == 403)
                throw ({
                    status: 403,
                    result: "",
                    resume: "Erro personalizado"
                });
            else
                throw ({
                    result: "",
                    status: r.response.status,
                    resume: "deu erro no else"
                });

        })

        console.log("~ RESPOSTA API ~", response);
        return response;
    }
    formatting(args) {
        let res = FormatterValidator.formatting(args, this.initialValues)
        this.payload = res;
        return res
    }

    async searching() {
        //console.log("FAZENDO A BUSCA USANDO SERACHING... ")
        //console.log(this.payload)
        //console.log(this.initialValues);
        let where = [];

        // Object.entries(this.payload[0]).map((item, value) => {

        // });
        const d = this.payload[0];


        if (d.class_id)
            where.push(["A.class_id", "IN", d.class_id.map(item => item.code)]);

        if (d.class_number)
            where.push(["A.class_number", "=", d.class_number]);

        if (d.course_id)
            where.push(["A.course_id", "IN", d.course_id.map(item => item.code)]);

        if (d.vacancy)
            where.push(["A.vacancy", "=", d.vacancy]);

        if (d.dt_start_start && d.dt_start_end)
            where.push(["A.dt_start", "BETWEEN", [d.dt_start_start, d.dt_start_end]]);

        if (d.dt_end_start && d.dt_end_end)
            where.push(["A.dt_end", "BETWEEN", [d.dt_end_start, d.dt_end_end]]);

        if (d.client_id)
            where.push(["A.client_id", "IN", d.client_id.map(item => item.code)]);

        if (d.workload)
            where.push(["A.workload", "=", d.workload]);

        if (d.location_id)
            where.push(["A.location_id", "IN", d.location_id.map(item => item.code)]);

        if (d.class_modality_id)
            where.push(["A.class_modality_id", "IN", d.class_modality_id.map(item => item.code)]);

        if (d.special_permission_arr)
            where.push(["A.special_permission_arr", "LIKE", `${d.special_permission_arr}%`]);

        if (d.special_command_id)
            where.push(["A.special_command_id", "IN", d.special_command_id.map(item => item.code)]);

        if (d._created_user_id)
            where.push(["A._created_user_id", "IN", d._created_user_id.map(item => item.code)]);

        if (d._updated_user_id)
            where.push(["A._updated_user_id", "IN", d._updated_user_id.map(item => item.code)]);

        if (d._created_at_start && d._created_at_end)
            where.push(["A._created_at", "BETWEEN", [d._created_at_start, d._created_at_end]]);

        if (d._updated_at_start && d._updated_at_end)
            where.push(["A._updated_at", "BETWEEN", [d._updated_at_start, d._updated_at_end]]);

        if (d.class_status_id)
            where.push(["A.class_status_id", "IN", d.class_status_id.map(item => item.code)]);

        if (d.city_id)
            where.push(["A.city_id", "IN", d.city_id.map(item => item.code)]);

        if (d.student_certificate_config_id)
            where.push(["A.student_certificate_config_id", "IN", d.student_certificate_config_id.map(item => item.code)]);

        if (d.icp_certificate_config_id)
            where.push(["A.icp_certificate_config_id", "IN", d.icp_certificate_config_id.map(item => item.code)]);

        //console.log("where: ", where);
        return {
            leftJoin: true,
            where: where,
            orderBy: "A.folha_pgto_id DESC",
            rowsCountTotal: true,
            page: 1,
            limit: 10
        }
    }

}
