   
        export default class Model {    
            constructor() {    
                this.error = [];
        
                if (new.target === Model) {
                    throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
                }
        
                this.initialValues = {
                    
            "comissao_item_id": {
                onCreate: "0",
   type: "mediumint"
                }, 
            "colaborador_id": {
                   type: "mediumint"
                }, 
            "lancamento_id": {
                onCreate: "1",
   type: "int"
                }, 
            "comissao_efetivada": {
                   type: "decimal"
                }, 
            "folha_pgto_id": {
                onCreate: "1",
   type: "mediumint"
                }, 
            "comissao_item_status_id": {
                onCreate: "0",
   type: "mediumint"
                }, 
            "comissao_id": {
                onCreate: "1",
   type: "mediumint"
                }
                };
            }
            validate() {
                throw new Error('Método "validate" deve ser implementado pelas subclasses.');
            }
            create() {
                throw new Error('Método "create" deve ser implementado pelas subclasses.');
            }
            execute() {
                throw new Error('Método "execute" deve ser implementado pelas subclasses.');
            }
        }
        