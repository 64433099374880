import React, { useContext, useEffect,useState } from 'react'

import { Link } from 'react-router-dom'
import { AuthContext } from '../Contexts/Auth/AuthContext'
import Permission from '../utils/Permission';
import PagesMenu from '../presentation/pagesMenu.json';

function Aside() {
    const [openMenus, setOpenMenus] = useState({}); // Controla o colapso dos menus individualmente

    //console.clear();
    const { provider_user, provider_current_client, provider_permission, provider_screen_id } = useContext(AuthContext)
    //console.log("aside provider_permission", provider_current_client, provider_screen_id);
    //Permission.setCurrentClientId(provider_current_client.client_id);
    //Permission.setPermission(provider_permission);
    console.log("provider_user.client_id", provider_user);

    
  
  // Alternar collapse de menus
  const toggleMenuCollapse = (menuKey) => {
    setOpenMenus({
        [menuKey]: !openMenus[menuKey], // Apenas o menu clicado será alternado
      });
  };



    useEffect(() => {
        //console.log('%c useEffect - [ASIDE]: ', 'color: #000; background-color: green;');


    }, [])
    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            <img alt="image" className="rounded-circle" src={`//cdn.singularis.com.br/img/users/128x128/${provider_user.USER_IMG}`} style={{ maxWidth: '48px' }} />
                            <Link data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="block m-t-xs font-bold">{provider_user.USER_SHORT_NAME}</span>
                                <span className="text-muted text-xs block">Art Director <b className="caret"></b></span>
                            </Link>
                            <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                <li><Link className="dropdown-item" href="profile.html">Profile</Link></li>
                                <li><Link className="dropdown-item" href="contacts.html">Contacts</Link></li>
                                <li><Link className="dropdown-item" href="mailbox.html">Mailbox</Link></li>
                                <li className="dropdown-divider"></li>
                                <li><Link className="dropdown-item" href="login.html">Logout</Link></li>
                            </ul>
                        </div>
                        <div className="logo-element">
                            IN+
                        </div>
                    </li>
                    {/* <li>
                        <Link to="index.html"><i className="fa fa-th-large"></i> <span className="nav-label">Dashboards</span> <span className="fa arrow"></span></Link>
                        <ul className="nav nav-second-level collapse">
                            <li><Link to="index.html">Dashboard v.1</Link></li>
                            <li><Link to="dashboard_2.html">Dashboard v.2</Link></li>
                            <li><Link to="dashboard_3.html">Dashboard v.3</Link></li>
                            <li><Link to="dashboard_4_1.html">Dashboard v.4</Link></li>
                            <li><Link to="dashboard_5.html">Dashboard v.5 </Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="layouts.html"><i className="fa fa-diamond"></i> <span className="nav-label">Layouts</span></Link>
                    </li>
                    <li>
                        <Link to="#"><i className="fa fa-bar-chart-o"></i> <span className="nav-label">Graphs</span><span className="fa arrow"></span></Link>
                        <ul className="nav nav-second-level collapse">
                            <li><Link to="graph_flot.html">Flot Charts</Link></li>
                            <li><Link to="graph_morris.html">Morris.js Charts</Link></li>
                            <li><Link to="graph_rickshaw.html">Rickshaw Charts</Link></li>
                            <li><Link to="graph_chartjs.html">Chart.js</Link></li>
                            <li><Link to="graph_chartist.html">Chartist</Link></li>
                            <li><Link to="c3.html">c3 charts</Link></li>
                            <li><Link to="graph_peity.html">Peity Charts</Link></li>
                            <li><Link to="graph_sparkline.html">Sparkline Charts</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="mailbox.html"><i className="fa fa-envelope"></i> <span className="nav-label">Mailbox </span><span className="label label-warning float-right">16/24</span></Link>
                        <ul className="nav nav-second-level collapse">
                            <li><Link to="mailbox.html">Inbox</Link></li>
                            <li><Link to="mail_detail.html">Email view</Link></li>
                            <li><Link to="mail_compose.html">Compose email</Link></li>
                            <li><Link to="email_template.html">Email templates</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="metrics.html"><i className="fa fa-pie-chart"></i> <span className="nav-label">Metrics</span>  </Link>
                    </li>
                    <li>
                        <Link to="widgets.html"><i className="fa fa-flask"></i> <span className="nav-label">Widgets</span></Link>
                    </li>
                    <li className="active">
                        <Link to="#"><i className="fa fa-edit"></i> <span className="nav-label">Forms</span><span className="fa arrow"></span></Link>
                        <ul className="nav nav-second-level">
                            <li><Link to="form_basic.html">Basic form</Link></li>
                            <li className="active"><Link to="form_advanced.html">Advanced Plugins</Link></li>
                            <li><Link to="form_wizard.html">Wizard</Link></li>
                            <li><Link to="form_file_upload.html">File Upload</Link></li>
                            <li><Link to="form_editors.html">Text Editor</Link></li>
                            <li><Link to="form_autocomplete.html">Autocomplete</Link></li>
                            <li><Link to="form_markdown.html">Markdown</Link></li>
                        </ul>
                    </li>

                    <li>
                        <Link to="css_animation.html"><i className="fa fa-magic"></i> <span className="nav-label">CSS Animations </span><span className="label label-info float-right">62</span></Link>
                    </li>
                    <li className="landing_link">
                        <Link target="_blank" href="landing.html"><i className="fa fa-star"></i> <span className="nav-label">Landing Page</span> <span className="label label-warning float-right">NEW</span></Link>
                    </li>
                    <li className="special_link">
                        <Link to="package.html"><i className="fa fa-database"></i> <span className="nav-label">Package</span></Link>
                    </li> */}
                    {PagesMenu.map((item, index) => (
                        <li className={openMenus[index]?'active':''??item.active}>
                            <Link key={index} onClick={() => toggleMenuCollapse(index)} to="#"><i className={item.icon}></i> <span className="nav-label">{item.category_name}</span><span className="fa arrow"></span></Link>
                            <ul className="nav nav-second-level" aria-expanded={openMenus[index]?'true':'false'}>
                                {item.pages.map((item2, index2) => (
                                    <>
                                        {/* <li key={index2} className={`nav nav-second-level`+(item.active?'':' collapse')} aria-expanded="false"><Link to="form_advanced.html">{item2.screen_name}</Link></li> */}
                                        <li key={index2} className={`nav nav-second-level collapse ${openMenus[index] ? 'in' : ''}`}><Link to={item2.url}>{item2.screen_name}</Link></li>
                                    </>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>

            </div>
        </nav>
    );
}

export default Aside;

