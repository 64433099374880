   
        export default class Model {    
            constructor() {    
                this.error = [];
        
                if (new.target === Model) {
                    throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
                }
        
                this.initialValues = {
                    
            "lotacao_id": {
                onCreate: "0",
   type: "int"
                }, 
            "client_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "mediumint"
                }, 
            "colaborador_id": {
                onCreate: "1",
   type: "int"
                }, 
            "_created_at": {
                onCreate: "0",
 formatter: ["formatterDate"],
 mask: "dmYHis",
 type: "datetime"
                }, 
            "_created_user_id": {
                onCreate: "0",
 formatter: ["formatterInteger"],
  type: "int"
                }, 
            "_updated_at": {
                onCreate: "0",
  mask: "dmY",
 type: "datetime"
                }, 
            "_updated_user_id": {
                onCreate: "0",
   type: "int"
                }, 
            "lotacao_status_id": {
                onCreate: "0",
   type: "mediumint"
                }
                };
            }
            validate() {
                throw new Error('Método "validate" deve ser implementado pelas subclasses.');
            }
            create() {
                throw new Error('Método "create" deve ser implementado pelas subclasses.');
            }
            execute() {
                throw new Error('Método "execute" deve ser implementado pelas subclasses.');
            }
        }
        