import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <div className="float-right">
                Usuário Fulano de tal logado em <strong>V2409.140</strong>
            </div>
            <div>
                <strong> Mads Engenharia&copy; </strong> <script>document.write(new Date().getFullYear())</script>
            </div>
        </div>
    )
}

export default Footer