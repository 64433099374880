import React, { useState, useEffect } from 'react'
import { useApi } from "../../hooks/useApi";

//Função que constroe o Provider e também permite Consumir os Dados Globais
import { AuthContext } from "../../Contexts/Auth/AuthContext";

//Componente Provider para passar os valores para os Childrens
function AuthProvider({ children }) {
  const [provider_user, setUser] = useState();
  const [provider_screen_id, setScreenId] = useState();
  const [provider_session, setSession] = useState();
  const [provider_association, setProviderAssociation] = useState();
  const [provider_current_client, setProviderCurrentClient] = useState();
  const [provider_permission, setProviderPermission] = useState();
  let provider_tables = {};
  const api = useApi();

  useEffect(() => {
    //console.clear();
    //console.log("~~~~ FAZ A VERIFICAÇÃO DE SESSÃO ~~~~ - API");
    const validateToken = async () => {
      const storageUser = localStorage.getItem('user');
      const storageData = localStorage.getItem('authToken');
      const storageSession = localStorage.getItem('session');

      if (storageData) {
        setUser(JSON.parse(storageUser));
        setSession(JSON.parse(storageSession));
        /* const data = await api.validateToken(storageData);
        if (data.user) {
          //console.log("[usuario logado]", data);
          setUser(data.user);
          setSession(storageSession);
        } */
      }
    }
    validateToken();
  }, []);
  const setLogInSession = (response) => {    
    let _user = response.result.user;
    setUser(_user);
    setToken(response.result.token);
    setSession(response.result.session)    
    setLocalStorage("versionApp", { "version": "24.02.149", "description": "nenhuma" })
    setProviderAssociation(response.result.association);
    setProviderCurrentClient(response.result.current_client);
    setProviderPermission(response.result.permission);

    //        const userData = { USER_NAME: _user.USER_NAME, USER_IMG: _user.USER_IMG, USER_SHORT_NAME: _user.USER_SHORT_NAME,USER_LEVEL_NAME: _user.USER_LEVEL_NAME, USER_ID: _user.USER_ID, USER_TOKEN: _user.USER_TOKEN }

    localStorage.setItem("user", JSON.stringify(_user));
  }
  const changeClient = async (client_id) => {

    const response = await api.checkIn({ client_id: client_id, token: provider_user.USER_TOKEN });

    if (response.success) {
      if (response.result.user && response.result.token) {
        const newCurrentClient = response.result.current_client;
        setProviderCurrentClient((prev) => ({ ...prev, newCurrentClient }));
        //console.log("response", response);
        setLogInSession(response);
      }
    }
    return response;
  }

  const changeScreenId = async (screen_id) => {
    setScreenId(screen_id);
  }

  const signin = async (email, password) => {
    console.clear();
    const response = await api.signin(email, password);
    console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: DADOS DO USUARIO ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;', response);

    if (response.success) {
      if (response.result.user && response.result.token) {
        setLogInSession(response);
      }
    }
    return response;
  }

  const signout = async () => {
    //console.log("signout está sendo executada.");
    setUser(null);
    setToken('');
    await api.logout();
  }

  const setToken = (token) => {
    localStorage.setItem('authToken', token);
  }

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }

  return (
    <AuthContext.Provider value={{ provider_user, signin, changeClient, signout, provider_session, provider_association, provider_tables, provider_current_client, provider_permission, provider_screen_id }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider