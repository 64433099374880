import React, { useState, useEffect } from 'react';


import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import SingHelper from '../utils/SingHelper';
import { InputText } from 'primereact/inputtext';

const CustomDropdown = ({ field, formik, loading, options,type, optionSelected, listener, required, onBlurHandler, onChange, labelField, placeholder, label, ref,additionalName=null }) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [getOptions, setOptions] = useState([]);
  const [getPlaceholder, setPlaceholder] = useState(placeholder);

  const getDropdownOptions = (a) => {
    //console.clear();
    //console.log("getDropdownOptions: ",a);
    return SingHelper.arrayToDropDown(
      a[0], a[1], a[2], a[3]
    );
  };
  useEffect(() => {
    //console.clear();
    //console.log("DUSTOMDROPDOWN options",getOptions.find(option => option.code === formik.values[field]));
    updateOptions();
  }, []);

  useEffect(() => {
    if (listener) {
      let newListener = options;
      //newListener[0]['tb_certificate_config'] = listener;      
      updateOptions(newListener);
    }
  }, [listener]);
  useEffect(() => {
  //console.log("formik",selectedItem,formik.values[field]);
  if(formik.values[field])
    setSelectedItem(getOptions.find(option => option.code === formik.values[field]))
  }, [formik]);

  useEffect(() => {
    let newListener = options;
    updateOptions(newListener);
    setPlaceholder("Selecione um ítem");
  }, [loading]);

  const updateOptions = (args) => {
    const newOptions = getDropdownOptions(args ?? options);
    //console.log("newOptions", newOptions);
    if (newOptions)
      setOptions(newOptions);
  };

  const handleDropdownChange = (e) => {
    //console.clear();
    
    console.log("Mudou valor: ", additionalName, e.value.name);
    if (onBlurHandler)
      onBlurHandler();
    if (onChange)
      onChange(e.value.code);
    formik.setFieldValue(field, e.value.code); // Atualiza o valor no Formik
    
    if (additionalName)
      formik.setFieldValue(additionalName, e.value.name); // Atualiza o valor no Formik

    if (optionSelected)
      formik.setFieldValue(optionSelected, e.value.name); // Atualiza o valor no Formik
    

  };
  let isRequired = required || formik.errors[field] ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";

  return (
    <>
        <label htmlFor={field}>{label}</label>
      {
        type == "text" ?
          <>
            <InputText
              className={`form-control pl-1`}
              readOnly
              value={!!selectedItem?selectedItem.name:"Carregando..."}
            />
          </>
          :
          <>

            <Dropdown
              id={field}
              name={field}
              {...loading}
              ref={ref}
              value={getOptions.find(option => option.code === formik.values[field])}
              onChange={handleDropdownChange}
              options={getOptions}
              optionLabel={labelField}
              placeholder={getPlaceholder}
              className={`form-control ${isRequired} pl-1`}
              emptyMessage="Não há resultados2"
            />
            {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}
          </>
      }
    </>
  );
}

export default CustomDropdown;
