 
import React from 'react';
import { Link } from 'react-router-dom';
import Pages from '../pages.json';
const Breadcrumb = (props) => {
    console.log(Pages.pages[props.page].screen_name);
    const page = Pages.pages[props.page];
    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-12">
                    <h2>{page.screen_name}</h2>
                    <ol className="breadcrumb">

                        {/* {Pages.pages.folha_pgto_item_id.map((item, index) => (
                    ))} */}

                        <li className="breadcrumb-item">
                            <Link to="/welcome">Início</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={page.directory_label}>{page.directory}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Folha de pagamento</strong>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    )
}
export default Breadcrumb