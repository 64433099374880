
import React, { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export default function ShowToast({ resume }) {
    const toastRef = useRef(null);
    const showMessage = (ref) => {
        ref.current.show({ severity: resume.severity, summary: resume.summary, detail: resume.detail, life: 3000 });
    };
    useEffect(() => {
        if (resume !== null) {
            showMessage(toastRef)
        }
        
    }, [resume])
    
    return (
        <>
            {
                    (resume != null && resume!==undefined) &&
                <Toast ref={toastRef} position="bottom-right" />
            }
            {/* <div className="card flex justify-content-right">
            </div> */}

        </>
    )
}
