
import { useState, useEffect } from 'react';
//import { ReadSearch } from '../Domain/services/readSearch';

function useRead(array,ReadSearch) {
  const [result, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  ////console.log("array: ",array);

  useEffect(() => {

    // Lógica para ler os dados, por exemplo, de uma API
    // Neste exemplo, estou simulando uma requisição assíncrona
    const fetchData = async () => {
      try {
//console.clear();
console.log(`%c~ * ~ USE-READ :: ~~~~~~~~~~~~~~~~~~~~~~~~~~ HOOK response.data.result de ${array.table} ~ * ~`, 'color: white; background-color: pink; padding: 4px;font-size: 18px',array);
/* FAZ A LISTAGEM DOS ULTIMOS REGISTROS CADASTRADOS NO SISTEMA */
        const readSearch = new ReadSearch();
        const thisResult = await readSearch.execute(array)
          .then(response => {
            setIsLoading(false);
            console.log(`%c~ * ~ USE-READ :: ~~~~~~~~~~~~~~~~~~~~~~~~~~ HOOK response.data.result de ${array.table} ~ * ~`, 'color: white; background-color: pink; padding: 4px;font-size: 18px',response.data);

            
            if (response.data.result.length > 0) {
              setList(response.data.result);
              setIsLoading(false);
            }
            else
              return [];
          }).catch(error => {
            console.log("error:::::::::::::::::::::::::", error);
          })


      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
        setList([]);
        setIsLoading(false);
      }
    };

    fetchData(); // Chama a função fetchData assim que o componente é montado

    // Cleanup: caso necessário, você pode retornar uma função que será executada ao desmontar o componente
    return () => {
      // Lógica de limpeza, se necessário
    };
  //}, []); // O array vazio assegura que o useEffect seja executado apenas uma vez, quando o componente é montado
}, []); // Reage a mudanças em config e nas dependências adicionais

  return [result, isLoading, error];
}

export default useRead;
