import React, { useEffect, useState, useRef, useContext, forwardRef } from 'react'
import { Link } from 'react-router-dom'

/* PRIME REACT */
import { Paginator } from 'primereact/paginator';

/* COMPONENTES */
import SweetButton from '../../components/SweetButton';

/* UTILITÁRIOS */
import SingMask from '../../utils/SingMask';
import usePermission from '../../utils/Permission.js';

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import FragmentModelCreate from './fragmentModelCreate';

/* import { ExportXLS } from '../../services/folha_pgto/exportXls.js'
 *///import "jspdf-autotable";
//import jsPDF from "jspdf";

/* ESTILOS CSS */
import 'primereact/resources/themes/lara-light-purple/theme.css';
import useCase from './useCase.js';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import ShowToast from '../../components/Toast.jsx';
import { usePaginator } from '../../utils/Paginator.js'


const FragmentList = forwardRef((props, ref) => {
    const btnDelete = useRef();
    const modalRef = useRef();
    const containerRef = useRef(null);

    const { getTotalRecords } = usePaginator()

    const { setAuth, auth, provider_session, provider_current_client, provider_permission } = useContext(AuthContext)
    const { listData, getBroadcast } = useContext(ContentContext)

    const { getResult, isLoading, onResume, first, rows, template1, handlerDelete, broadCast, reloadList, onPageChange } = useCase();
    const [isShow_paginator, setShow_paginator] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [isShowModalChange, setModalChange] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState([]);
    //const [permission, setPermission] = useState({});
    const [isDisabledButtonDeleteGroup, setIsDisabledButtonDeleteGroup] = useState(true);
    //const permissionManager = new Permission("tela Folha Pgto");
    const {
        hasStatus,
        hasFlag
    } = usePermission(provider_permission);

    const handleCheckboxChange = (event) => {
        const { checked, value } = event.target;
        if (checked)
            setCheckboxValues(prev => [...prev, value]);
        else
            setCheckboxValues(prev => prev.filter(item => item !== value));
    };

    const handleCheckAll = (e) => {
        setCheckboxValues([])
        const checkboxes = containerRef.current.querySelectorAll('.form-check-input');
        checkboxes.forEach(checkbox => {
            if (isCheckedAll == true)
                setCheckboxValues(prev => [...prev, checkbox.value]);
        });
    };

    useEffect(() => {
        if (btnDelete.current != undefined) {
            let RES = checkboxValues.length > 0 ? false : true
            setIsDisabledButtonDeleteGroup(RES)
        }

    }, [checkboxValues])

    useEffect(() => {
        if (getBroadcast != null)
            if (getBroadcast.command == "resetButtonDeleteGroup")
                setIsDisabledButtonDeleteGroup(true)
    }, [getBroadcast])

    useEffect(() => {
        console.clear();
      
        reloadList(null);
    }, [])

    useEffect(() => {
        if (isLoading == false && isReady == false)
            setIsReady(true)
    }, [isLoading])

    useEffect(() => {
        //console.log('%c~ * ~ FRAGMENTLIST :: getResult foi atualizado ~ * ~', 'color: white; background-color: pink; padding: 4px;font-size: 18px');
        //console.log("listData", listData);
        setCheckboxValues([])
    }, [listData])

    const openModal = (e) => {
        //console.log("openModal fragmentList", e);
        modalRef.current?.open(true)
    };
    const openModalEdit2 = (e) => {
        //console.log("openModalEdit2", e);
        //setModalChange(true)
        modalRef.current?.open(e)
    };

    return (
        <>
            {
                isLoading &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }
            <FragmentModelCreate ref={modalRef} checkboxValues={checkboxValues} isOpen={isShowModalChange} />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Folha de pagamemento</h5>
                                <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                        <i className="fa fa-wrench"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-user">
                                        <li><a href="#" className="dropdown-item">Config option 1</a>
                                        </li>
                                        <li><a href="#" className="dropdown-item">Config option 2</a>
                                        </li>
                                    </ul>
                                    <a className="close-link">
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="ibox-content">

                                {/* end card header */}
                                {isReady == false ?
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                        </button>
                                    </div> : (
                                        <div className="card-body">
                                            <div className="listjs-table" id="customerList">
                                                <div className="row g-4 mb-3">
                                                    <div className="col-sm-auto">
                                                        <div className="list-grid-nav hstack gap-1">
                                                            {/* <button type="button" className="btn btn-success add-btn" onClick={broadCast} id="create-btn" data-bs-toggle="modal" data-bs-target="#exampleModalgrid"><i className="ri-add-line align-bottom me-1"></i> Novo</button> */}
                                                            <button className="btn btn-success" onClick={() => openModal(true)}><i className="ri-add-line align-bottom me-1"></i> Novo</button>
                                                            {<SweetButton
                                                                props={{
                                                                    button: {
                                                                        disabled: isDisabledButtonDeleteGroup,
                                                                        ref: btnDelete,
                                                                        className: "btn btn-soft-danger btn-danger ml-2",
                                                                        icon: "ri-delete-bin-2-line",
                                                                        text: "Excluir"
                                                                    },
                                                                    swlConfig: {
                                                                        type: "dialog",
                                                                        icon: "question",
                                                                        title: "Deseja excluir os registros selecionados?",
                                                                        text: "",
                                                                        confirmButtonText: "Sim, estou ciente.",
                                                                        confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                    },
                                                                    actions: {
                                                                        IfConfirmed: () => handlerDelete(checkboxValues), // Passar como uma função
                                                                        IfDenied: ""
                                                                    }
                                                                }} />
                                                            }

                                                            {/* <button className="btn btn-soft-danger" onClick={() => new ExportXLS().generate({ template: "Folha de Pagamento", type: "xls", body_content: getResult, outPutFilename: "Turmas - total" })}><i className="ri-printer-line"></i></button> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="d-flex justify-content-sm-end">
                                                            <div className="search-box ms-2">
                                                                <input type="text" className="form-control search"
                                                                    placeholder="Procurar..." />
                                                                <i className="ri-search-line search-icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-card mt-3 mb-1">
                                                    <table className="table align-middle table-nowrap table-striped table-bordered table-hover" id="customerTable">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col" style={{ width: '50px' }}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} type="checkbox"
                                                                            id="checkAll" value="option" />
                                                                    </div>
                                                                </th>
                                                                <th className="sort" data-sort="folha_pgto_id">Cód.</th>
                                                                <th className="sort" data-sort="folha_pgto_name">Descrição</th>
                                                                <th className="sort" data-sort="centro_custo_id">Centro de custo</th>
                                                                <th className="sort" data-sort="valor_total">Valor total</th>
                                                                <th className="sort" data-sort="mes_competencia">Mês</th>
                                                                <th className="sort" data-sort="ano_competencia">Ano</th>
                                                                <th className="sort" data-sort="_created_at">Criado em</th>
                                                                <th className="sort" data-sort="folha_pgto_status_id">Status</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all" ref={containerRef}>
                                                            {!listData.length ? <>
                                                                <tr>
                                                                    <td colSpan="14">
                                                                        <div className="noresult">
                                                                            <div className="text-center">
                                                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                                                                                    trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                                                <h5 className="mt-2">Sinto muito! Não há resultados.</h5>
                                                                                <p className="text-muted mb-0">Não encontramos nenhum registro em nosso banco de dados.</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </> :
                                                                (
                                                                    listData.map(el => (
                                                                        <tr key={el.folha_pgto_id}>
                                                                            <th scope="row">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" checked={checkboxValues.includes(el.folha_pgto_id)} name="chk_child" value={el.folha_pgto_id} onChange={handleCheckboxChange} />
                                                                                </div>
                                                                            </th>
                                                                            <td nowrap="nowrap">{el.folha_pgto_id}</td>
                                                                            <td nowrap="nowrap">{el.folha_pgto_name}</td>
                                                                            <td nowrap="nowrap">{el.centro_custo_name}</td>
                                                                            <td nowrap="nowrap">{SingMask.toMonetary(el.valor_total)}</td>
                                                                            <td nowrap="nowrap">{el.mes_competencia}</td>
                                                                            <td nowrap="nowrap">{el.ano_competencia}</td>
                                                                            <td nowrap="nowrap">{SingMask.toDate(el._created_at, "dmYHi")}</td>
                                                                            <td nowrap="nowrap">{el.folha_pgto_status_name}</td>
                                                                            <td nowrap="nowrap">
                                                                                <div className="dropdown d-inline-block">
                                                                                    {
                                                                                        //hasFlagA02504 &&
                                                                                        hasFlag("A02", "504") &&
                                                                                        <Link to={`/admin/register/folha_pgto_item/${el.folha_pgto_id}`} state={{ stateData: [el] }} title="Listar inscrições" className="btn btn-primary" data-filter-tags="application intel marketing dashboard">
                                                                                            <i className="fa fa-search"></i> Ver Folha
                                                                                        </Link>
                                                                                    }
                                                                                    {
                                                                                        hasStatus("update", "503").includes(el.folha_pgto_status_id) &&
                                                                                        <button className="btn btn-success ml-1" onClick={() => openModalEdit2(el)}><i className="fa fa-edit"></i> Editar</button>
                                                                                    }
                                                                                </div>
                                                                            </td></tr>
                                                                    )))
                                                            }
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <th></th>
                                                                <th className="sort" data-sort="folha_pgto_id">Cód.</th>
                                                                <th className="sort" data-sort="folha_pgto_name">Descrição</th>
                                                                <th className="sort" data-sort="centro_custo_id">Centro de custo</th>
                                                                <th className="sort" data-sort="valor_total">Valor total</th>
                                                                <th className="sort" data-sort="mes_competencia">Mês</th>
                                                                <th className="sort" data-sort="ano_competencia">Ano</th>
                                                                <th className="sort" data-sort="_created_at">Criado em</th>
                                                                <th className="sort" data-sort="folha_pgto_status_id">Status</th>

                                                            </tr>
                                                        </tfoot>
                                                    </table>


                                                </div>

                                                <div className="d-flex justify-content-end">
                                                    <div className="pagination-wrap hstack gap-2">
                                                        {isShow_paginator &&
                                                            <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <ShowToast resume={onResume}></ShowToast>

        </>
    );
})

export default FragmentList;