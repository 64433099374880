import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API
});

export const useApi = () => ({
    validateToken: async (token) => {
        return {
            user: {
                userName: "CESAR NEGREIROS",
                userNameLabel: "NEGREIROS",
                userLevelName: "Operador",
                user_id: "401",
                userToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ik1hcmNlbG8gTmVncmVpcm9zIiwidXNlcl9pZCI6IjQwMSIsImlhdCI6MTY4Mjc3OTQ4NH0.DrNB_XQ6NQ0j0C-k1JAxvexZEphEpfSILbGFbBhxxM0"
            }
        };
        const response = await api.post('/validate', { token });
        return response.data;
    },/* teste ok */
    signin: async (args) => {
        //console.log("Função SING-IN chamada:: ", process.env.REACT_APP_HOST_AUTH);
        let param = args;

        if (args.token)
            param = { token: args.token };

        return await api.post(`${process.env.REACT_APP_HOST_AUTH}/v1/user/login`, param, {
            headers: {
                "Token-Authorization": 'none',
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br",
                'Content-Type': 'application/json'
            }
        }).then(r => {
            let u;
            if (Array.isArray(r.data.result))
                u = r.data.result[0];
            else
                u = r.data.result
            
            console.log("Resposta api :: signin", r);
            const resultSet = {
                user: {
                    USER_NAME: u.USER_NAME,
                    USER_IMG: u.USER_IMG,
                    USER_EMAIL: u.USER_EMAIL,
                    USER_SHORT_NAME: u.USER_SHORT_NAME,
                    USER_LEVEL_NAME: u.USER_LEVEL_NAME,
                    USER_ID: u.USER_ID,
                    USER_TOKEN: u.USER_TOKEN,
                },
                token: u.USER_TOKEN,
                session: {
                    CURRENT_CLIENT_ID: u.CURRENT_CLIENT_ID,
                    CURRENT_ENTITY_ID: u.CURRENT_ENTITY_ID,
                    DEFAULT_CLIENT_ID: u.DEFAULT_CLIENT_ID,
                    DEFAULT_ENTITY_ID: u.DEFAULT_ENTITY_ID,
                    LOGGED_CLIENT_ID: u.LOGGED_CLIENT_ID,
                    LOGGED_ENTITY_ID: u.LOGGED_ENTITY_ID
                },
                current_client: u.CURRENT_CLIENT,
                association: u.USER_ASSOCIATION,
                permission: u.PERMISSION
            }
            return { success: true, status: r.data.status, result: resultSet };
        }).catch(r => {
            //console.clear();
            //console.log("Catch useApi.js", r);
            //return r
            return { success: false, status: r.response.data.status, resume: r.response.data.fault_short_description };
        });

    },
    checkIn: async (args) => {
        //console.clear();
        //console.log("Função CHECK-IN chamada:: ", process.env.REACT_APP_HOST_AUTH);
        //console.log("args: ",args);
        let param = args;

        if (args.client_id)
            param = { client_id: args.client_id };

        return await api.patch(`${process.env.REACT_APP_HOST_AUTH}/v1/user/check-in`, param, {
            headers: {
                "Token-Authorization": args.token,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br",
                'Content-Type': 'application/json'
            }
        }).then(r => {
            let u;
            if (Array.isArray(r.data.result))
                u = r.data.result[0];
            else
                u = r.data.result

            //console.log("Resposta api :: check-in", r);
            const resultSet = {
                user: {
                    USER_NAME: u.USER_NAME,
                    USER_IMG: u.USER_IMG,
                    USER_EMAIL: u.USER_EMAIL,
                    USER_SHORT_NAME: u.USER_SHORT_NAME,
                    USER_LEVEL_NAME: u.USER_LEVEL_NAME,
                    USER_ID: u.USER_ID,
                    USER_TOKEN: u.USER_TOKEN,
                },
                token: u.USER_TOKEN,
                session: {
                    CURRENT_CLIENT_ID: u.CURRENT_CLIENT_ID,
                    CURRENT_ENTITY_ID: u.CURRENT_ENTITY_ID,
                    DEFAULT_CLIENT_ID: u.DEFAULT_CLIENT_ID,
                    DEFAULT_ENTITY_ID: u.DEFAULT_ENTITY_ID,
                    LOGGED_CLIENT_ID: u.LOGGED_CLIENT_ID,
                    LOGGED_ENTITY_ID: u.LOGGED_ENTITY_ID
                },
                current_client: u.CURRENT_CLIENT,
                association: u.USER_ASSOCIATION,
                permission: u.PERMISSION
            }
            return { success: true, status: r.data.status, result: resultSet };
        }).catch(r => {
            //console.clear();
            //console.log("Catch useApi.js", r);
            //return r
            return { success: false, status: r.response.data.status, resume: r.response.data.fault_short_description };
        });

    },
    logout: async () => {
        return { status: true };
        const response = await api.post('/logout');
        return response.data;
    }
});