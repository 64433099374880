import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import { AuthContext } from '../Contexts/Auth/AuthContext'

const Header = () => {
    const { provider_current_client, provider_user, provider_association } = useContext(AuthContext)
    return <div className="row border-bottom">
        <nav className="navbar navbar-static-top" role="navigation" style={{marginBottom: 0}}>
            <div className="navbar-header">
                <Link className="navbar-minimalize minimalize-styl-2 btn btn-primary " to="#"><i className="fa fa-bars"></i> </Link>
                <form role="search" className="navbar-form-custom" action="search_results.html">
                    <div className="form-group">
                        <input type="text" placeholder="Campo de pesquisa" className="form-control" name="top-search" id="top-search" />
                    </div>
                </form>
            </div>
            <ul className="nav navbar-top-links navbar-right">
                <li>
                    <span className="m-r-sm text-muted welcome-message">{provider_user.USER_NAME.toUpperCase()}</span>
                </li>
                <li className="dropdown">
                    <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
                        <i className="fa fa-map-marker"></i> [ Teresina ]
                        {/* <i className="fa fa-map-marker"></i> [ = $_SESSION['FILIAIS_INIT']['filial_name'] ; ] */}
                    </Link>
                    <ul className="dropdown-menu dropdown-messages">

                        <li>
                            <Link to="#">
                                <div>
                                    Nenhuma filial cadastrada
                                </div>
                            </Link>
                        </li>

                        <li className="dropdown-divider"></li>


                        <li>
                            <Link to="/change_location?uri=<?= $_SERVER['REQUEST_URI'].'&filial='.$v;?>">
                                <div>
                                    <i className="fa fa-map-marker fa-fw"></i> 
                                </div>
                            </Link>
                        </li>

                    </ul>
                </li>
                <li className="dropdown">
                    <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
                        <i className="fa fa-envelope"></i> 
                    </Link>
                    <ul className="dropdown-menu dropdown-messages">
                        <li>
                            <div className="dropdown-messages-box">
                                <Link className="dropdown-item float-left" to="profile.html">
                                    <img alt="image" className="rounded-circle" src="/madsengenharia.com.br/admin/img/profile_small.jpg" />
                                </Link>
                                <div className="media-body">
                                    <small className="float-right">46h ago</small>
                                    <strong>$_SESSION['USER_NAME'];</strong> foi adicionado ao sistema <strong>Usuário Root</strong>. <br />
                                    <small className="text-muted">2 dias atrás às 7:58 pm -  date("d") - 2; .02.2022</small>
                                </div>
                            </div>
                        </li>

                        <li className="dropdown-divider"></li>
                        <li>
                            <div className="text-center link-block">
                                <Link to="mailbox.html" className="dropdown-item">
                                    <i className="fa fa-envelope"></i> <strong>Ler todas as notificações</strong>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="dropdown">
                    <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
                        <i className="fa fa-bell"></i>
                    </Link>
                    <ul className="dropdown-menu dropdown-alerts">
                        <li>
                            <Link to="#">
                                <div>
                                    <i className="fa fa-envelope fa-fw"></i> Nenhuma mensagens
                                    <span className="float-right text-muted small">4 minutos atrás</span>
                                </div>
                            </Link>
                        </li>
                        <li className="dropdown-divider"></li>

                        <li>
                            <Link to="#">
                                <div>
                                    <i className="fa fa-twitter fa-fw"></i> Não há notificações
                                </div>
                            </Link>
                        </li>

                        <li className="dropdown-divider"></li>
                        <li>
                            <Link to="#">
                                <div>
                                    <i className="fa fa-upload fa-fw"></i> Serviços atualizados
                                    <span className="float-right text-muted small">4 minutos atrás</span>
                                </div>
                            </Link>
                        </li>
                        <li className="dropdown-divider"></li>
                        <li>
                            <div className="text-center link-block">
                                <Link to="#">
                                    <strong>Ver todos os alertas</strong>
                                    <i className="fa fa-angle-right"></i>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </li>


                <li>
                    <Link to="/">
                        <i className="fa fa-sign-out"></i> Sair
                    </Link>
                </li>
            </ul>

        </nav>
    </div>
};
export default Header;