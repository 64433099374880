/* 
https://react-bootstrap.netlify.app/docs/components/buttons/
 */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link} from 'react-router-dom'

import { ReactComponent as Loader } from '../assets/icons/LoadButton.svg'


function ButtonShortCut({ page,title }) {
    const [isLoading, setLoading] = useState(false);
//console.log("page",page);
    useEffect(() => {
        /* function simulateNetworkRequest() {
          return new Promise((resolve) => setTimeout(resolve, 2000));
        }
    
        if (isLoading) {
          simulateNetworkRequest().then(() => {
            setLoading(false);
          });
        } */
    }, [isLoading]);

    //const handleClick = () => setLoading(true);

    return (
        <nav className="shortcut-menu d-none d-sm-block">
            <input type="checkbox" className="menu-open" name="menu-open" id="menu_open" />
            <label for="menu_open" className="menu-open-button ">
                <span className="app-shortcut-icon d-block"></span>
            </label>
            {/* <a href="#" className="menu-item btn" data-toggle="tooltip" data-placement="left" title="Scroll Top">
                <i className="fal fa-arrow-up"></i>
            </a>
            <a href="page_login-alt.html" className="menu-item btn" data-toggle="tooltip" data-placement="left" title="Logout">
                <i className="fal fa-sign-out"></i>
            </a>
            <a href="#" className="menu-item btn" data-action="app-fullscreen" data-toggle="tooltip" data-placement="left" title="Full Screen">
                <i className="fal fa-expand"></i>
            </a>
            <a href="#" className="menu-item btn" data-action="app-print" data-toggle="tooltip" data-placement="left" title="Print page">
                <i className="fal fa-print"></i>
            </a> 
            <a href="#" className="menu-item btn" data-action="app-voice" data-toggle="tooltip" data-placement="left" title="Voice command">
                <i className="fal fa-microphone"></i>
            </a>*/}
            {/* <Link to={`/admin/register/${page}/post`} title={title} className="menu-item btn" >
                <i className="fal fa-plus"></i>
            </Link>        */}     
        </nav>
    );
}

export default ButtonShortCut;