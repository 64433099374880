import axios from 'axios';

class ApiService {
    constructor(baseURL) {
        this.api = axios.create({
            baseURL: baseURL,
            headers: {
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br",

            },

        });
    }

    // Método para adicionar cabeçalhos adicionais
    setAuthToken(token) {
        this.api.defaults.headers.common['Token-Authorization'] = `${token}`;
    }

    // Requisição GET
    async get(endpoint, params = {}) {
        try {
            const response = await this.api.get(endpoint, { params });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Requisição POST
    async post(endpoint, data = {}, headers = {}) {
        try {
            const response = await this.api.post(endpoint, data, { headers });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Requisição PUT
    async put(endpoint, data = {}, headers = {}) {
        try {
            const response = await this.api.put(endpoint, data, { headers });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Requisição DELETE
    async delete(endpoint, headers = {}) {
        try {
            const response = await this.api.delete(endpoint, { headers });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Manipulação de erros
    handleError(error) {
        if (error.response) {
            // Resposta do servidor com erro (4xx ou 5xx)
            console.error("Error response:", error.response);
            throw new Error(error.response.data.message || 'Erro na API');
        } else if (error.request) {
            // Nenhuma resposta do servidor
            console.error("No response:", error.request);
            throw new Error('Nenhuma resposta do servidor');
        } else {
            // Outro tipo de erro
            console.error("Error", error.message);
            throw new Error('Erro na requisição');
        }
    }
}

export default ApiService;
