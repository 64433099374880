import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

// type={type} field={field} required={required} placeholder={placeholder} label={label}  formik={formik} args={args} 
const CustomInputMonetary = ({ field, label, required, readonly, type, args, formik,max=null }) => {
    const handleFieldChange = (e) => {
        let valor = e.value != null ? e.value.toString() : e.value;
        formik.setFieldValue(field, valor);
    };

    const configs = {
        mode: 'currency',
        currency: 'BRL',
        locale: 'pt-br',
    };
    const options = args && args.currency
        ? {
            mode: args.mode,
            currency: args.currency,
            locale: args.locale,
        }
        : configs;
    const toReal = (value) => {
        if (value)
            return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


    }

    return (
        <>
            <label htmlFor={field}>{label}</label>
            {
                type == "text" ?
                    <>
                        <InputText
                            className={`form-control pl-1`}
                            readOnly
                            value={toReal(formik.values[field])}
                        />
                    </>
                    :
                    <>
                        <InputNumber
                            id={field}
                            value={formik.values[field]}
                            className={`form-control pl-1 ${classNames({ 'p-invalid': formik.touched[field] && formik.errors[field] })}`}
                            name={field}
                            readOnly={type == "text" || readonly || args?.action === "view" ? true : false}
                            onBlur={formik.handleBlur}
                            onValueChange={(e) => handleFieldChange(e)}
                            {...(max !== undefined && { max })} 
                            {...options}
                        />
                        {formik.touched[field] && formik.errors[field] && <div className='formik-error'>{formik.errors[field]}</div>}</>
            }



        </>
    );
};

export default CustomInputMonetary;
