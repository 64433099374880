import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectSingleDbClient = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, onChange, labelField, placeholder, label, ref }) => {
    const [listclient, isLoadingclient, isErrorclient] = useRead({
        table: "db_register.tb_client",
        src: 'client',
        where: [["client_id", "!=", "0"]],
        orderBy: "client_name asc",
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label ?? "Regional"
            }
            options={[listclient, 'tb_client', 'client_name', 'client_id']}
            labelField="name"
            loading={isLoadingclient}
            //listener={onListener}
            optionSelected='client_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}           
            onChange={onChange ? (e) => onChange(e) : undefined}

            /* {...{onChange={(e)=> onChange(e)}}} */
            placeholder="Regional"
            formik={formik}
        />

    );
}

export default SelectSingleDbClient;  