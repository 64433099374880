
        import instance from '../../infra/db/instance';
        import ClassModel  from './model';
        import { FormatterValidator } from '../../infra/adapters/formatterValidator'
       
        export default class useServicePut extends ClassModel {
        constructor() {
            super();
        }
        initValues() {
            return true
        }
       
        validation(args) {
            let res = FormatterValidator.formatAndValidate(args, this.initialValues)
            return res
        }
       
        formatting(args) {
            let res = FormatterValidator.formatting(args, this.initialValues)
            this.payload = res;
            return res
        }
       
        masking(args) {
            let res = FormatterValidator.masking(args, this.initialValues)
            this.payload = res;
            return res
        }
       
        async execute(action,args) {
            let config = { post: ["create", "post"], patch: ["patch", "PATCH"] }
            //console.log("CLASS CREATE CONFIG: ", config[action], args);
            const response = await instance({
            url: `/folha_pgto?method=${config[action][0]}`,
            method: `${config[action][1]}`,
            data: args
            }).catch(r=>{
               return r
            })
            return response
        }

        async sendToN8n(action) {
            //console.clear();
            console.log("formatted",action);
            
            const response = await instance({
                url: `http://191.252.101.154:5678/webhook/atualizaFolhaPgto`, // URL para publicação de mensagens
                //url: `http://191.252.192.20:15672/api/exchanges/%2F/mads_eng/publish`, // URL para publicação de mensagens
                //payload: "[  {   \"comissao_item_id\": \"{{comissao_item_id}}\",    \"comissao_efetivada\": \"{{comissao_efetivada}}\",    \"colaborador_id\": \"{{colaborador_id}}\",    \"_created_at\": \"2024-10-15 00:00:00\",    \"comissao_item_status_id\": \"52799\",    \"payload\": {      \"sub\": \"1234567890\",      \"name\": \"Naiara Oliveira\",      \"user_id\": \"272\",      \"iat\": 1516239022    }  }]", // a mensagem que você quer enviar
                method: 'POST', // método HTTP            
                data: action
            }).catch(r => {
                return r;
            });
    
            return response;
        }
       
        }
        