        
        import instance from '../../infra/db/instance';
        import { AbstractDelete } from './abstractDelete';
        
        export default class UseServiceDelete extends AbstractDelete {
            constructor(payload) {
                super(payload);
            }
            async delete(data) {
                let newData = data;
                
                console.log('%c~ * ~ useServiceDelete.js :: delete() ~ * ~', 'color: white; background-color: red; padding: 4px;font-size: 18px');                                               
                
                console.log("~~ Payload enviado: ",newData,typeof newData);
                const response = await instance({
                    url: `/folha_pgto`,
                    method: "DELETE",
                    data: newData
                }).catch(r => {
                    console.log("CATCH INSTANCE", r);
                })
        
                console.log("~ RESPOSTA API ~", response);
                return response;
            }
        
        }