import React, { useEffect, useState, useRef, useContext } from 'react'

import { InputText } from 'primereact/inputtext';
import useRead from '../domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';
import ApiService from '../domain/services/ApiService';
import { ArrayToQParams } from '../application/utils/arrayToQParams';
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';


const CustomInputDbDataSumValorRecebido = ({ field, formik, loading, data, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref, args, additionalName = null }) => {

    const [isLoadingstatus, setIsLoadingstatus] = useState(true);
    const [liststatus, setListstatus] = useState([]);

    /* const [liststatus, isLoadingstatus, isErrorstatus] = useRead({
        table: "db_financeiro.tb_lancamento",
        select: "SUM(valor_efetivado) AS valor_efetivado",
        src: 'lancamento',
        where: [["A.document_origin_id", "=", args.proposta_id]],
        //orderBy: "status_name asc",
        limit: "1"
    }, ReadSearch, [args.proposta_id]); */

    const payload = {
        table: "db_financeiro.tb_lancamento",
        select: "SUM(valor_efetivado) AS valor_efetivado",
        src: 'lancamento',
        where: [["A.document_origin_id", "=", args.proposta_id]],
        //orderBy: "status_name asc",
        limit: "1"
    };
    //console.log("liststatus:: ok :dddddddddddddddddddddddddddddddd", liststatus);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingstatus(true);

            const apiService = new ApiService(process.env.REACT_APP_HOST_API_CLIENT); // Substitua pela sua URL       
            const token = localStorage.getItem('authToken');
            apiService.setAuthToken(token);

            try {
                const result = await apiService.get('/lancamento' + `${ArrayToQParams(payload)}&method=search&search=true`);
                console.log(result);
                setListstatus(result.result);
                setIsLoadingstatus(false);
            } catch (error) {

                console.error("Erro ao buscar dados:", error.message);
            } /* finally {
                setIsLoadingstatus(false);
            } */
        };
        if (args.proposta_id > 0)
            fetchData();

    }, [args.proposta_id])

    return (
        <>
            <label htmlFor={field}>{label}</label>
            <br />
            {isLoadingstatus ?
                <>
                    <InputText
                        type="text"
                        required={required}
                        placeholder='Carregando... '
                        loading={isLoadingstatus}
                        readonly
                        label={label}
                        args={args} />
                    <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" /> </>

                :
                <InputText
                    type="text"
                    required={required}
                    value={`R$ ${liststatus[0].valor_efetivado}`}
                    loading={isLoadingstatus}
                    readonly
                    label={label}
                    args={args} />
            }
        </>
    );
}
{/* <CustomDropdown
            field={field}
            label={label ?? "Status da turma"}
            options={[liststatus, 'tb_status', 'status_name', 'status_id']}
            labelField="name"
            additionalName={additionalName}
            loading={isLoadingstatus}
            //listener={onListener}
            optionSelected='status_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Status da turma"
            formik={formik}
        /> */}
export default CustomInputDbDataSumValorRecebido; 