import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
import {AbstractReadSearch} from './abstractReadSearch';

export class ReadSearch extends AbstractReadSearch {
    constructor() {        
      super();
    }  
    async execute(payload) {   
      //console.log("DEU CERTO");
      //console.log("payload.src",payload.src);
        const response =  await instance({
            url: `/${payload.src}${ArrayToQParams(payload)}&method=search&search=true`,
            method: "GET"
          })
          console.log("response: READSEARCH: ok ",response);
        return response;
      }  
      
  }