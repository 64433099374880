import React, { useEffect, useState, useContext } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import UseServicePutFolha_pgto_item from './useServicePut.js';
import { ContentContext } from '../../Contexts/Contents/Context.js';
import { useLocation } from 'react-router-dom';

function UCCreateFolha_pgto_item() {
    const location = useLocation();

    const { setListData, editData, action, setAction, setEditData, listData, getBroadcast,workSpace } = useContext(ContentContext)

    const [onResume, setOnResume] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false);

    const initialValues = {
        "valor": "",
        "colaborador_id": "",
        "folha_pgto_id": ""        
    };

    function substituirElemento(array, objetoSubstituto, indiceAlvo) {
        return array.map((elemento, indice) => {
            // Se o índice for o índice alvo, substitua o objeto
            if (elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]) {
                return objetoSubstituto[0];
            }
            // Caso contrário, mantenha o objeto original
            return elemento;
        });
    }

    const teste = async(payload)=>{
        const action = "post";
        console.clear();
        console.log("FUNÇÃO TESTE " + action, payload);
        const dataFolha = location.state.stateData;

        const formattingCreate = new UseServicePutFolha_pgto_item();
        let msg = {};
        let formatted = formattingCreate.formatting([payload]);
        console.log("Valores formatados:", formatted);

        setIsSubmiting(true)

        try {
            let created = await formattingCreate.execute(action, formatted);
            console.log("created", created);

            if (action == "post") {
                if (created.data.result.success.length > 0) {
                    setListData(prev => [...prev, created.data.result.success[0]]);
                    msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                } else {
                    msg = { summary: "Falha!", detail: "Registro não foi criado.", severity: "error" };
                }
            }

            if (action == "patch") {
                var ok = substituirElemento(listData, created.data.result.success, "folha_pgto_item_id");
                setListData(ok);
                msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
            }

            setOnResume(msg);

            formik.resetForm();
            setIsSubmiting(false)
        }catch (error) {
            console.log("error", error);
            setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
            setIsSubmiting(false)
        }
        
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {

            let errors = {};
            data.folha_pgto_id = workSpace.folha_pgto.folha_pgto_id
            const useServicePutFolha_pgto_item = new UseServicePutFolha_pgto_item();
            let erro = useServicePutFolha_pgto_item.validation([data]);
            errors = erro;
            console.log("errors Encontrados: ",data,errors);
            console.log("workSpace",workSpace);
            
            return errors
        },
        onSubmit: async (payload, { setSubmitting }) => {

            console.log("Agora vai " + action, payload,workSpace);
            const formattingCreate = new UseServicePutFolha_pgto_item();
            let msg = {};
            let formatted = formattingCreate.formatting([payload]);
            console.log("Valores formatados:", formatted);

            setIsSubmiting(true)

            try {
                let created = await formattingCreate.execute(action, formatted);
                console.log("created", created);

                if (action == "post") {
                    if (created.data.result.success.length > 0) {
                        setListData(prev => [...prev, created.data.result.success[0]]);
                        msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                    } else {
                        msg = { summary: "Falha!", detail: "Registro não foi criado.", severity: "error" };
                    }
                }

                if (action == "patch") {
                    var ok = substituirElemento(listData, created.data.result.success, "folha_pgto_item_id");
                    setListData(ok);
                    msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
                }

                setOnResume(msg);

                formik.resetForm();
                setIsSubmiting(false)
            } catch (error) {
                console.log("error", error);
                setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
                setIsSubmiting(false)
            }
            setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: true,
    })
    const handleClickCancel = (button) => {
        setAction("post")
        setEditData(null)
        formik.resetForm();
    }
   

    useEffect(() => {
        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [location] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        if (location.state != null) {
            const params = location.pathname.split("/");
            if (params[params.length - 1] == "patch") {
                const data = location.state.stateData;
                setAction("patch")
                setEditData(data)
                formik.setValues(data);
            }
        }
    }, [location])
    
    useEffect(() => {
     
                //formik.setFieldValue("folha_pgto_id",workSpace.folha_pgto.folha_pgto_id);
       
    }, [])

    useEffect(() => {
        console.clear();
        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [ErrorMessage] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        console.log(ErrorMessage);
    }, [ErrorMessage])

    useEffect(() => {
        if (getBroadcast != null) {
            console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [getBroadcast] ::: fica de olho no listener ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            console.log(getBroadcast);
            setAction("post")
            formik.resetForm()
        }
    }, [getBroadcast])

    return {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,      
        setIsSubmiting,
        teste,  
        handleClickCancel
    }
}

export default UCCreateFolha_pgto_item;
