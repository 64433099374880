import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
//import Alert from '../../components/Alert.jsx';
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

import Permission from '../../utils/Permission.js';
import UCCreateSubscriber from './useCaseCreate.js';
import CustomInput from '../../components/CustomInput.jsx';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import UCCreateColaborador from './useCaseCreate.js';

/* select personalizados */


/* select personalizados */
import SelectSingleDbStatus from '../../components/selectSingleDbStatus.jsx';


const FragmentModelCreate = forwardRef((props, ref) => {
    const { provider_session } = useContext(AuthContext)
    const [isShowModalChange, setModalChange] = useState(props.isOpen);
    const { listData, getBroadcast, setEditData, setAction } = useContext(ContentContext)

    const modalRef = useRef();
    const buttonRef = useRef(null);
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        closeModal1,
        handleClickCancel,
    } = UCCreateColaborador();

    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        open: (param) => {
            if (typeof param === 'object' && param !== null) {
                setAction("patch");
                formik.setValues(param)
            } else {
                setAction("post");
            }
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    }));

    const openModal = () => {
        if (modalRef.current) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    };

    const closeModal = () => {
        buttonRef.current.click(); // Simula o clique no botão
        setModalChange(false)
    };

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])

    useEffect(() => {
        closeModal()
    }, [closeModal1])

    useEffect(() => {
        console.log("useEffect onResume", onResume, editData);
        if (editData != null)
            openModal();
    }, [editData])

    useEffect(() => {
        console.log("useEffect", isShowModalChange);
        if (isShowModalChange) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        } else {
            buttonRef.current.click(); // Simula o clique no botão
        }
    }, [isShowModalChange])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }

            <div className="modal" id="exampleModalgrid" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalgridLabel">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalgridLabel">
                                {
                                    action == "patch" ? "Ediçãodwdwd" : "333333333333"
                                }
                            </h5>

                            <button type="button" className="close" ref={buttonRef} data-dismiss="modal"><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>

                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation" noValidate id="form-create-update">
                                <div className="panel-content">
                                    <div className="form-row">
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="colaborador_name" id="validationCustom01" required placeholder="Nome completo" label='Nome do colaborador' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-cpf" field="cpf" id="validationCustom01" required placeholder="Cpf do colaborador" label='CPF' formik={formik} args={{ action: action, mask: "999.999.999-99" }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-numeric" field="ctps" max="9999" placeholder="Nº CTPS" label="Número da carteira de trabalho" formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-datetime" field="dt_nascimento" id="validationCustom01" required placeholder="Data de nascimento" label='Data de nascimento' formik={formik} args={{ action: action, mask: "99/99/9999" }} />
                                        </div>
                                        {
                                            action == "patch" && <div className="col-md-auto mb-3">
                                                <SelectSingleDbStatus field="colaborador_status_id" label="Status" formik={formik} data={{ screen_id: 506 }} />
                                            </div>

                                        }<div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="filiacao_01_name" id="validationCustom01" placeholder="Nome completo da mãe" label='Nome da mãe' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="filiacao_02_name" id="validationCustom01" placeholder="Nome completo do pai" label='Nome do pai' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput field="sexo" label="Sexo" formik={formik} data={{ screen_id: 506 }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="naturalidade" id="validationCustom01" required placeholder="Naturalidade" label='Naturalidade' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="uf" id="validationCustom01" required placeholder="Estado da federação" label='UF' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput field="estado_civil_id" label="Estado civil" formik={formik} data={{ screen_id: 506 }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="instrucao" id="validationCustom01" placeholder="Instrução" label='Instrução' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="doc_rg" id="validationCustom01" placeholder="Número do RG" label='Número do RG' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-datetime" field="doc_rg_emissao" id="validationCustom01" placeholder="Data de emissão" label='Data de emissão' formik={formik} args={{ action: action, mask: "99/99/9999" }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="doc_rg_orgao_emissor" id="validationCustom01" placeholder="Órgão emissor" label='Órgão emissor' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="doc_cnh" id="validationCustom01" placeholder="Nº CNH" label='Nº CNH' formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-datetime" field="dt_admissao" id="validationCustom01" required placeholder="Data de adimissão" label='Data de adimissão' formik={formik} args={{ action: action, mask: "99/99/9999" }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="forma_salario" id="validationCustom01" placeholder="Forma de pagamento" label='Forma de pagamento' formik={formik} args={{ action: action }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                    {
                                        action == "patch" &&
                                        <MyButton
                                            name="btnCancel"
                                            text="Cancelar"
                                            type="button"
                                            onClick={(el) => handleClickCancel("btnCancel")}
                                            icon="fal fa-times"
                                            btnClass="btn btn-default ml-auto" />
                                    }
                                    {
                                        action == "patch" &&
                                        <MyButton
                                            name="btnPatch"
                                            text="Novo"
                                            type="button"
                                            onClick={(el) => handleClickCancel("btnNew")}
                                            icon="fal fa-plus"
                                            btnClass="btn btn-success ml-2" />
                                    }
                                    <MyButton
                                        name="btnSave"
                                        text="Salvar"
                                        type="submit"
                                        loading={isSubmiting}
                                        icon="fal fa-save"
                                        btnClass="btn btn-primary ml-2" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentModelCreate;