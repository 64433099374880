import { react, useEffect, useState, useContext } from "react";
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ContentContext } from '../Contexts/Contents/Context.js';

export const usePaginator = () => {
    const { listData, getBroadcast, action,setBroadcast } = useContext(ContentContext)

    const [first, setFirst] = useState([0, 0, 0]);
    const [rows, setRows] = useState([10, 0, 0]);
    const [getPageCount, setPageCountOptions] = useState();
    const [getPaginator, setPaginator] = useState({});

    const [getOrigin, setOrigin] = useState('ALL');
    const [getQueryParams, setQueryParams] = useState('');
    const [getTotalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState("Pressione 'Enter' para próxima página.");

    const onPageChange = (e, index) => {
        e.page = e.page + 1;
        e.origin = getOrigin;
        e.q = getQueryParams;
        console.log("AO MUDAR DE PÁGINA: ", e);
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));        
        setBroadcast({
            "id":"pagination.js-onPageChange"+Math.random(),
            "page": "pearson",
            "action": "search",
            "instance": "fragmentList",
            "command": "pagination",
            "data":e
        })        
    };

    const onPageInputChange = (event, options) => {
        //setPageCountOptions(options);          
        setCurrentPage(event.target.value);
    };

    const onPageInputKeyDown = (event, options) => {
console.log("onPageInputKeyDown",options);
        if (event.key === 'Enter') {
            const page = parseInt(currentPage);

            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Valor de ser entre 1 e ${options.totalPages}.`);
            } else {
                let _first = [...first];
                _first[0] = currentPage ? options.rows * (page - 1) : 0;
                setFirst(_first);
                setPageInputTooltip("Pressione 'Enter' para próxima página.");
                options.page=event.target.value
        console.log("AO INFORMAR UMA PÁGINA: ", event,options);
                setBroadcast({
                    "id":"pagination.js-onPageChange",
                    "page": "pearson",
                    "action": "search",
                    "instance": "fragmentList",
                    "command": "pagination",
                    "data":options
                })
            }
        }
    };
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        PrevPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Anterior</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Próxima</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return (
                    <span className={className} style={{ userSelect: 'none' }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 'Todas', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <>
                    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        ir para <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={(e) => onPageInputChange(e, options)} />
                    </span>
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} a {options.last} de {options.totalRecords}
                    </span>
                </>
            );
        }
    };
    useEffect(() => {

        if (getBroadcast) { 
            //console.log(" ::::::::::::::::::: getBroadcast NO PAGINATOR ::::::::::::::::::::: "+Math.random(), getBroadcast);
            if(getBroadcast.action == "search")
            setTotalRecords(getBroadcast.data.result_rows_count_total) 
        }

    }, [getBroadcast])

    return { first, getPaginator, pageInputTooltip, rows, getPageCount, getOrigin, getQueryParams, template1, currentPage, getTotalRecords, setOrigin, setFirst, setRows, setPageCountOptions, setQueryParams, onPageChange, onPageInputChange, setCurrentPage, setTotalRecords, setPageInputTooltip, setPaginator };

}