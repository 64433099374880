import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
//import { AbstractSearch } from './abstractSearch';
import { FormatterValidator } from '../../infra/adapters/formatterValidator'
import Model from './model';
/* import { loadItemFromLocalStorage,saveToLocalStorage } from "../../utils/localStorageUser"; */

export default class useServiceSearch extends Model {
    constructor() {
        super();
    }


    async execute(args, token) {
        instance.defaults.headers["Token-Authorization"] = token;
        console.log("useCaseSearch::execute: ", args);
        const response = await instance({
            url: `/colaborador${ArrayToQParams(args)}&method=search&search=true`,
            method: "GET"
        }).catch(r => {
            console.log("useCaseSearch::CATCH: ", r.response);
            if (r.response.status == 403)
                throw ({
                    status: 403,
                    result: "",
                    resume: "Erro personalizado"
                });
            else
                throw ({
                    result: "",
                    status: r.response.status,
                    resume: "deu erro no else"
                });

        })

        console.log("~ RESPOSTA API ~", response);
        return response;
    }
    formatting(args) {
        let res = FormatterValidator.formatting(args, this.initialValues)
        this.payload = res;
        return res
    }

    async searching() {

        let where = [];

        const d = this.payload[0];

        if (d.colaborador_id)
            where.push(["A.colaborador_id", "IN", d.colaborador_id.map(item => item.code)]);

        if (d.colaborador_name)
            where.push(["A.colaborador_name", "LIKE", `${d.colaborador_name}%`]);

        if (d.cpf)
            where.push(["A.cpf", "=", d.cpf]);

        if (d.ctps)
            where.push(["A.ctps", "LIKE", `${d.ctps}%`]);

        if (d.dt_nascimento_start && d.dt_nascimento_end)
            where.push(["A.dt_nascimento", "BETWEEN", [d.dt_nascimento_start, d.dt_nascimento_end]]);

        if (d.colaborador_status_id)
            where.push(["A.colaborador_status_id", "IN", d.colaborador_status_id.map(item => item.code)]);

        if (d._created_user_id)
            where.push(["A._created_user_id", "IN", d._created_user_id.map(item => item.code)]);

        if (d._created_at_start && d._created_at_end)
            where.push(["A._created_at", "BETWEEN", [d._created_at_start, d._created_at_end]]);

        if (d._updated_user_id)
            where.push(["A._updated_user_id", "IN", d._updated_user_id.map(item => item.code)]);

        if (d._updated_at_start && d._updated_at_end)
            where.push(["A._updated_at", "BETWEEN", [d._updated_at_start, d._updated_at_end]]);

        if (d.filiacao_01_name)
            where.push(["A.filiacao_01_name", "LIKE", `${d.filiacao_01_name}%`]);

        if (d.filiacao_02_name)
            where.push(["A.filiacao_02_name", "LIKE", `${d.filiacao_02_name}%`]);

        if (d.naturalidade)
            where.push(["A.naturalidade", "LIKE", `${d.naturalidade}%`]);

        if (d.uf)
            where.push(["A.uf", "LIKE", `${d.uf}%`]);

        if (d.estado_civil_id)
            where.push(["A.estado_civil_id", "IN", d.estado_civil_id.map(item => item.code)]);

        if (d.instrucao)
            where.push(["A.instrucao", "LIKE", `${d.instrucao}%`]);

        if (d.doc_rg)
            where.push(["A.doc_rg", "LIKE", `${d.doc_rg}%`]);

        if (d.doc_rg_emissao_start && d.doc_rg_emissao_end)
            where.push(["A.doc_rg_emissao", "BETWEEN", [d.doc_rg_emissao_start, d.doc_rg_emissao_end]]);

        if (d.doc_rg_orgao_emissor)
            where.push(["A.doc_rg_orgao_emissor", "LIKE", `${d.doc_rg_orgao_emissor}%`]);

        if (d.doc_cnh)
            where.push(["A.doc_cnh", "LIKE", `${d.doc_cnh}%`]);

        if (d.dt_admissao_start && d.dt_admissao_end)
            where.push(["A.dt_admissao", "BETWEEN", [d.dt_admissao_start, d.dt_admissao_end]]);

        if (d.forma_salario)
            where.push(["A.forma_salario", "LIKE", `${d.forma_salario}%`]);

        return {
            leftJoin: true,
            where: where,
            orderBy: "A.colaborador_id DESC",
            rowsCountTotal: true,
            page: 1,
            limit: 10
        }
    }

}