
import React, { useEffect, useState, useContext,useRef} from 'react'
import { ContentContext } from '../../Contexts/Contents/Context.js';
/* COMPONENTES */
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';

/* importação da class crud */
import ClassUseServiceSearch from './useServiceSearch';
import ClassUseServiceDelete from './useServiceDelete';
//import UCCreateClass from './useCaseCreate';

/* UTILITÁRIOS */
//import Permission from '../../utils/Permission.js'
import { usePaginator } from '../../utils/Paginator.js'
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

function useCase() {
    const { listData, setListData, getBroadcast, action, setAction, isLoading, setIsLoading, setBroadcast } = useContext(ContentContext)
    const { provider_user,provider_session} = useContext(AuthContext)
    //console.log("provider_session",provider_session);

    const { first, rows, onPageChange, template1 } = usePaginator();

    const [onResume, setOnResume] = useState(null);
    const [getResult, setResult] = useState([]);
    const [error, setError] = useState(null);
    const modalRef = useRef();

    const data = {
        leftJoin: true,
        where: [],
        orderBy: "A.folha_pgto_id DESC",
        rowsCountTotal: true,
        page: 1,
        limit: 10
    }

    const openModalEdit = (e) => {
        //console.log("openModalEdit useCase",e);
        //setModalChange(true)
        //const modal = new window.bootstrap.Modal(modalRef.current);
        //modal.show();         
        //formi
        modalRef.current?.open(true)
    };

    const refresh = (page, r) => {
        //console.clear();
        //console.log('%c~ * ~ USECASE::refresh ~ * ~', 'color: white; background-color: blue; padding: 4px;font-size: 12px', page, r);
        reloadList({ page: page, limit: r });
    }


    const handlerDelete = async (data) => {
        console.clear();
        //console.log('%c~ * ~ URL ENVIADA A API - useCase :: DELETE ~ * ~', 'color: white; background-color: blue; padding: 4px;font-size: 18px');
        //console.log(data);

        try {
            setIsLoading(true);
            const response = await new ClassUseServiceDelete().delete(data)
            const result = response.data.result;

            if (response.status == 201) {
                if (!!result.success.length) {
                    const newgetList = listData.filter(element => !result.success.includes(element.folha_pgto_id));                    
                    setListData(newgetList);
                    setIsLoading(false);
                    setOnResume({ summary: "Concluído!", detail: "Exclusão bem sucedida.", severity: "success" });
                }

                if (!!result.fail.length) {
                    setOnResume({ summary: "Falha!", detail: "Há exclusões não efetivadas.", severity: "error" });
                    setBroadcast({
                        "page": "class",
                        "action": "post",
                        "segment": "list",
                        "command": "resetButtonDeleteGroup"
                    })
                }
            }

        } catch (error) {
            setOnResume({ summary: "Falha!", detail: "Não foi possível concluir a requisição", severity: "error" });
        }
        setIsLoading(false);
    }

    const reloadList = (newData) => {
        //console.clear();

        //console.log('%c~ * ~ USECASE::reloadList ~ * ~', 'color: white; background-color: green; padding: 4px;font-size: 18px', newData);


        let sql = data;
        if (newData != null)
            sql = { ...data, ...newData };
        setIsLoading(true);
        const fetchData = async () => {
            try {       
                //console.clear();         
                setIsLoading(true);
                const classUseServiceSearch = new ClassUseServiceSearch();
                let getResult2 = await classUseServiceSearch.execute(sql,provider_user.USER_TOKEN)
                //console.log("REQUEST ENVIADO: ",sql);
                //console.log(":::: RESULTADO DA BUSCA DE TURMAS :::: ", getResult2.data);

                setListData(getResult2.data.result);
                setBroadcast({
                    "page": "class",
                    "action": "search",
                    "segment": "list",
                    "body": {
                        "command": "none"
                    },
                    "data": getResult2.data
                })                
                setIsLoading(false);
            } catch (error) {
                //console.log("useCase::DEU ERRO",error);
                
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }

    useEffect(() => {        
        if (getBroadcast) {
            if (getBroadcast.command == "pagination") {
                //console.log('%c~ * ~ USE EFFECT - getBroadcast::[] ~ * ~', 'color: #fff; background-color: blue; padding: 10px;', getBroadcast);
                refresh(getBroadcast.data.page, getBroadcast.data.rows)
            }
        }
    }, [getBroadcast])

   
    function verificaPropriedades(a, b) {
        return b.map(b_item => {
            const matchingIndex = a.findIndex(el => el.folha_pgto_id === b_item.folha_pgto_id);
            if (matchingIndex !== -1) {
                return { ...b_item, ...a[matchingIndex] };
            }
            return b_item;
        });
    }
    useEffect(() => {        
        if (listData != null && action == "post") {
            setResult((current) => [...current, listData]);
        }

        if (listData != null && action == "patch") {
            const newResult = verificaPropriedades(listData, getResult);
            setAction("post")
            setResult(newResult);
        }

        if (listData != null && action == "search") {            
            setResult(listData);
        }
        setIsLoading(false);
    }, [listData]);

    useEffect(() => {
        let msg = "";
        if (error != null) {
            const data = error;
            if (data.status == 403) {
                msg = { summary: "Atenção!", detail: "data.resume.fail[0]", severity: "error" };
            } else {
                msg = { summary: "Falha!", detail: "Error", severity: "error" };
            }
            setOnResume(msg);
        }

    }, [error]);

    return { getResult, isLoading, onResume, first, rows, template1, handlerDelete, reloadList, onPageChange,openModalEdit };
}

export default useCase;      